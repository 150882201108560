import Service from '@/helpers/service'

export default {

    get(page, params) {
        return Service().get('abbreviation?page=' + page,  {params});
    }, 
    show(id) {
        return Service().get('abbreviation/show/'+id);
    },    
    create(params) {
        return Service().post('abbreviation/add', params);
    },
    update(id, params) {
        return Service().post('abbreviation/update/'+id, params);
    },
    delete(id) {
        return Service().post('abbreviation/delete/'+id);
    }
}